import * as React from 'react'
import Seo from 'components/seo'
import Layout from 'layouts/zh'
import Button from 'components/Base/Button'
import SectionContent from 'components/SectionContent'
import versionLifecycle from 'assets/images/terms/lifecycle/version-lifecycle.png'
import SubscribeForm from 'components/SubscribeForm'
import { NavMenu } from '../../solutions/iptv/index.zh'
import * as styles from './index.module.less'

const Lifecycle = () => {
  const { useState } = React
  const [showForm, handleForm] = useState(false)

  return (
    <Layout>
      <Seo
        title="产品生命周期管理说明-神策数据"
        description="神策数据致客户的一封信：关于产品生命周期管理与高可用版本的提供"
        keywords="产品生命周期管理,神策数据"
        saTitle="首页-用户中心（产品生命周期管理说明）"
      />
      <main className={styles.Lifecycle}>
        <div className="title-wrap">
          <h2 className="title show-pc">致客户的一封信：关于产品生命周期管理与高可用版本的提供</h2>
          <h2 className="title show-mobile">
            致客户的一封信：
            <br />
            关于产品生命周期管理与高可用版本的提供
          </h2>
          <Button btnType="default" onClick={() => handleForm(true)}>
            订阅升级通知
          </Button>
        </div>
        <NavMenu
          menus={[
            {
              url: '/terms/lifecycle.html',
              name: '产品生命周期管理',
              isActive: true,
            },
            {
              url: '/terms/lifecycle_details.html',
              name: '产品版本服务支持声明',
            },
          ]}
        />
        <SectionContent className="content-wrap">
          <h3 className="honorific">尊敬的神策客户：</h3>
          <section className="content">
            <p>
              首先，很感谢您选择成为神策产品的订阅制客户（以下简称“客户”）。软件产品也会经历从孕育、诞生、成长、成熟、衰亡等阶段，这是每个软件产品或产品版本都会经历的软件生命周期。
            </p>
            <p>
              随着新技术的应用、新业务的引入，老版本的功能会逐渐无法满足客户日渐更新的业务成长需求，对产品进行主动式生命周期管理、持续迭代，更有利于帮助您及时响应新业务需求、抓住市场机会。
            </p>
            <p>
              <b>
                神策自 2015
                年成立以来，一直参考全球软件行业最佳实践和行业标准，对公司各产品线都建立了主动式的产品版本管理机制，明确产品从诞生到终止的规范、并始终如一践行。
              </b>
            </p>
            <p>
              我们相信，
              <b>持续迭代和不断升级的软件产品，才能在性能、可用性、伸缩性、扩展性、安全性等方面全面保障客户的利益。</b>
            </p>
          </section>
        </SectionContent>
        <SectionContent className="software-version">
          <h3 className="explain">「软件版本号」说明</h3>
          <p className="content">
            神策采用四位版本号体系，第一、二、三位版本号为主版本号，如「神策分析 2.2.0」、「营销云
            4.0.3」；该版本号说明，适用于神策分析云产品线的所有产品、神策营销云 4.0.3 以上版本产品。第四位版本号，为
            build 号，仅供神策内部产研团队用于内部识别使用。
          </p>
          <ul className="version-list">
            <li>
              第一位版本号：神策的第一位版本号从 0 递增，基于公司产品战略层面的定义，第一位升级 +1
              ，即意味着公司该产品线的战略升级
            </li>
            <li>
              第二位版本号：神策的第二位版本号从 0 递增，该版本升级
              +1，意味着该版本有提升客户价值、产品能力侧的重要功能发布
            </li>
            <li>
              第三位版本号：神策的第三位版本号从 0 递增，该版本升级
              +1，主要服务于主要功能特性的计划迭代、客户临时或紧急需求响应
            </li>
            <li>第四位版本号：build 号，供内部识别版本使用</li>
          </ul>
        </SectionContent>
        <SectionContent className="version-lifecycle">
          <h3 className="explain">「版本生命周期」说明</h3>
          <p className="table show-mobile">
            <a href={versionLifecycle} target="_blank" rel="noreferrer">
              <img src={versionLifecycle} alt="" />
            </a>
          </p>
          <p className="table show-pc">
            <img src={versionLifecycle} alt="" />
          </p>
          <div className="mg-texit show-mobile">点击图片查看大图</div>
        </SectionContent>
        <SectionContent className="version-deploy">
          <h3 className="explain">「版本部署及升级」说明</h3>
          <section className="content">
            <p>
              新签约订阅制客户，默认部署神策最新发布的「正式版本」；已签约订阅制客户，在合同服务期内的可支持版本，默认可免费升级到最新发布的「正式版本」。
            </p>
            <p>
              为最大限度的保障客户使用神策产品的便捷性、高可用，以及后续升级的灵活性，我们的正式版本（即 GM
              版本）会维护最近期的三个版本（如
              2.3.x、2.2.x、2.1.x）；并会主动与您一起，推进您所使用的产品升级到最新版本，充分保障您的利益。
            </p>
            <p>
              停止售卖版本（即 EOM 版本）和停止服务版本（即 EOS
              版本），我们会根据实际发布计划提前一定周期（如一个月以上）发布在神策官网（www.sensorsdata.cn）、神策公众号（SensorsDataCrop
              或微信搜索“神策数据”）等官方渠道，便于您的查找确认；与此同时，我们还会通过您预留的邮箱、电话等，及时安排您专属的客户成功经理，一对一通知到您的项目对接人或系统管理员。
            </p>
            <p>
              根据部分客户需求所研发的独立开发、独立升级的最小技术单元，我们称之为「附加产品组件」（即 add-on
              组件），add-on 组件不会包括在通用产品版本升级范畴。如果您的业务对 add-on
              组件有需求，可以联系您专属的销售经理或客户成功经理，进行增购，我们也会不定期推出相关促销活动；此外，针对帮助神策产品迭代的种子客户，会获得相应种子客户的福利回馈，如赠送相关
              add-on 组件的免费使用期或使用权限。
            </p>
            <p style={{ marginTop: '50px' }}>
              <b>
                基于以上产品版本管理机制，神策的产品线委员会会规划各产品线和产品版本的生命周期管理计划，并会通过各种通道（如官网、邮件、电话、企业微信群等方式）主动跟您沟通版本迭代升级动态与功能细节；每个版本升级后，我们也会提供相应的培训课程与操作视频，便于您团队内部第一时间掌握最新的功能与使用方法。
              </b>
            </p>
            <p>
              <b>
                关于产品生命周期管理、产品版本管理机制，如果您有任何建议或想法，可以及时告知您的专属销售经理或客户成功经理。
              </b>
              我们非常欢迎每一位神策的客户，可以参与到我们的产品生命周期管理计划中，充分反馈您对我们产品的需求与期望。与此同时，在旧版本停止销售或服务前，我们也会提前为您做好升级通知、升级计划与具体安排，并跟您一起去评估与商讨您关心的问题。
            </p>
            <p>
              最后，感谢您对神策数据一如既往的支持，希望通过您的参与和我们的主动式服务，能让神策产品为您的业务充分发挥应有的价值。给客户带来价值，是我们一直所践行的价值观！
            </p>
            <h4 className="author">
              <span />
              <span>
                曹 犟<br />
                神策数据联合创始人 & CTO
              </span>
            </h4>
          </section>
          <section className="sd-button-group">
            <Button btnType="primary" onClick={() => handleForm(true)}>
              订阅升级通知
            </Button>
          </section>
        </SectionContent>
      </main>
      {showForm && <SubscribeForm handleClose={() => handleForm(false)} spare_channel="product_subscription" />}
    </Layout>
  )
}

export default Lifecycle
